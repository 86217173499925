/* styles.css */
.selectBox {
  /* margin: -1px; */
  min-width: 400px;
  padding: 0;
  margin-bottom: 5px;
  
}

.formControl {
  padding: 0;
}

.menuItem {
  padding: 0;
}

.divider {
  margin: 0;
}
.NoteMessage{
  font-size: 13px;
  
}
.file-and-folder-box{
  display: flex;
  gap: 10px;
}