@import "../../styles/helpers";

.widgetBlock {
  padding: 12px 0;
  background-color: $base-light-gray;
  border-radius: 8px;
}

.widgetBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  
}

.smallWidget {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  .mutedPink {
    background-color: #FF566830;
  }

  .mutedYellow {
    background-color: #FFC40530;
  }

  .mutedTeal {
    background-color: #41D5E230;
  }

  .mutedViolet {
    background-color: #4D53E030;
  }
//  доделать для остальных цветов прогресс баров
}

.image {
  margin-right: 16px;
  width: 80px;
  height: 80px;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.userParams {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 32px;
}

.goals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 54px 0;
}

.goalsTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.goal {
  display: flex;
  flex-direction: column;
}

.activity {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.statsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding: 24px 36px;
  border-radius: 8px;

  @media (max-width: breakpoint-max(sm)) {
    padding: 20px 24px;
  }

  .pieImg {
    width: 48px;
    height: 48px;
    margin-right: 10px;
  }
}

.responsiveWidget {
  @media (max-width: breakpoint-max(lg)) {
    padding: 24px 12px;
  }
}


.cardMainContainer {
  display: flex;
  justify-content: space-between;
}

.cardContainer {
  // height: 133px;
  // width: 30%;
  // padding: 15px;
  // border-radius: 10px;
  // // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  // box-shadow: rgba(0, 0, 0, 0.1) 4px 4px 3px;
  // margin: 20px;
  height: 133px;
  width: 30%;
  padding: 15px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 6px 8px 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-inline: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.cardContainer:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 20px;
}

.cardContainer1 {
  // background-color: #caf4ff;
  background-color: #fff;
  border-left: 7px solid #105ec0;
}

.cardContainer2 {
  background-color: #e0fbe2;
  background-color: #fff;
  border-left: 7px solid #0a6847;
}

.cardContainer3 {
  background-color: #ffefef;
  background-color: #fff;
  border-left: 7px solid #7aba78;
}

.cardContainer4 {
  background-color: #fffbda;
  background-color: #fff;
  border-left: 7px solid #abab00;
}

// .cardContainerText {
// }

.cardContainerText1 {
  color: #105ec0;
}
.cardContainerText2 {
  color: #0a6847;
}
.cardContainerText3 {
  color: #7aba78;
}
.cardContainerText4 {
  color: #abab00;
}

.contentContainer {
  display: flex;
  gap: 10px;
}

.Treecontainer{
  height: 100%;
}