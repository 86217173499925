.chunk-container {
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 99%;
  min-height: 71vh;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  // overflow-y: auto;
}
.chunk-box {
  display: flex;
  position: relative;
  align-items: center;
  margin-top: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 10px;
  padding: 5px;
  align-items: end;
  width: 97%;
  margin-left: 25px;
}
.chunk-title {
  margin-top: 10px;
  margin-left: 20px;
}

.chunk-btn {
  // cursor: pointer;
  display: flex;
  padding: 20px;
  margin-inline: 20px;
  // margin-bottom: 20px;
  // margin-right: 5px;
  // justify-content: flex-end;
}

.chunk-setting-algo,
.chunk-setting-overlap,
.chunk-setting-size {
  //  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  padding: 20px;
  gap: 10px;
  width: 23%;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
}
.chunk-setting-algo {
  display: flex;
  padding: 20px;
  gap: 10px;
  width: 25%;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
  align-items: center;
}
.chunk-setting-overlap,
.chunk-setting-size {
  display: flex;
  padding: 20px;
  gap: 10px;
  width: 20%;
  border-radius: 10px;
  margin-right: 20px;
  margin-left: 20px;
  align-items: center;
}
.chunk-preview {
  display: flex;
  min-height: 71vh;
  padding: 10px;
  width: 100%;
}

.chunk-preview-column {
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
.outer-extract-chunk-container {
  width: 50%;
}

.chunk-preview-content {
  padding: 10px;
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  // width: 200%;
}

.chunk-vertical-line {
  border-left: 1px solid #cdcdcd;
  margin-inline: 5px;
  height: 600px;
  width: 1px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .chunk-container {
    width: 95%;
    margin-left: 2.5%;
  }

  .chunk-box {
    width: 95%;
    margin-left: 2.5%;
  }

  .chunk-setting-algo,
  .chunk-setting-overlap,
  .chunk-setting-size {
    width: 30%;
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .chunk-container {
    width: 90%;
    margin-left: 5%;
    padding: 10px;
  }

  .chunk-box {
    flex-direction: column;
    align-items: stretch;
    width: 90%;
    margin-left: 5%;
  }

  .chunk-setting-algo,
  .chunk-setting-overlap,
  .chunk-setting-size {
    width: 90%;
    margin: 10px auto;
  }

  .chunk-preview {
    flex-direction: column;
  }

  .chunk-preview-content {
    width: 100%;
  }

  .chunk-vertical-line {
    display: none;
  }
}

@media (max-width: 480px) {
  .chunk-container {
    width: 100%;
    margin-left: 0;
    border-radius: 0;
  }

  .chunk-box {
    width: 100%;
    margin-left: 0;
    border-radius: 0;
  }
}

.tooltip-dummy-data-cotainer {
  //   border: 2px solid grey;
  width: 55vw;
  display: flex;
  border-radius: 10px;
  gap: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow-x: scroll;
  //   height: 40vh;
  padding: 10px;
  background-color: #fff;

  .dummy-data-title {
    font-weight: 600;
    font-size: 16px;
    color: #000;
  }
  .dummy-data-subtitle {
    color: #000;
    font-size: 16px;
  }
  .dummy-data-content {
    height: 40vh;
    margin-top: 10px;
    border-top: 2px solid grey;
    .dummy-data-content-image {
      //   height: 100%;
      margin-top: 10px;
      width: 350px;

      object-fit: fill;
    }
  }
}
.tooltip-dummy-data-inner-container {
  padding: 10px;
  //   border: 2px solid grey;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
