/* pipelineexecutions.css */

.MainContainer {
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  width: 98%;
  margin-left: 10px;
  min-height: 71vh;
}

.pipeline-container {
  display: flex;
  align-items: center;
  width: 100%;
  padding-inline: 20px;
  margin-bottom: 40px;
  justify-content: space-evenly;
  /* padding-bottom: 50px; */
  margin-right: 30px;
  margin-top: 10px;
}

.pipeline-inner-container {
  display: flex;
  align-items: center;
  // padding: 20px;
  flex-wrap: wrap;
  margin-right: 10px;
  //  margin-top: 10px;
}
.step-button {
  display: inline-block;
  cursor: not-allowed;
  background: blue;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
  padding: 2px 11px;
  font-weight: 600;
  color: #fff;
  background-color: #13107d;
  transition: background-color 0.2s ease-in-out;
  // cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.pipeline-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table-extraction {
  padding-inline: 7rem;
}

.pipeline-step {
  // margin-top:30px ;
  margin-top: 45px;
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.All-btn {
  border-radius: 5px;
  border: none;
  margin-right: 10px; /* Adjust margin as needed */
  padding: 10px 20px; /* Adjust padding as needed */
  font-weight: 600;
  margin-top: 2%;
  color: #fff;
  background-color: rgb(19, 16, 125); /* Dark blue background */
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

.pipeline-circle {
  margin-bottom: 10px;
  width: 30px;
  height: 30px;

  // background-color: #3498db;
  background-color: #3f51b5;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
  // position: absolute;
  // top: -30px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.pipeline-button-box {
  /* padding: 30px 30px; */
  font-size: 16px;
  // font-weight: bold;
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  color: #000;
  border: 2px solid #696969;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline: 35px;
  position: relative;
  .BorderBottom {
    border-bottom: 2px solid #e6e6e6;
    width: 100%;
    top: 41%;
    position: absolute;
  }
  @media (max-width: 1300px) {
    padding-inline: 20px;
  }
  @media (max-width: 1160px) {
    padding-inline: 7px;
  }
}
.pipeline-json-box {
  /* padding: 30px 30px; */
  font-size: 16px;
  // font-weight: bold;
  background-color: aliceblue;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  color: #000;
  border: 2px solid #696969;
  border-radius: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline: 35px;
  position: relative;
  margin-left: 30px;
  .BorderBottom {
    border-bottom: 2px solid #e6e6e6;
    width: 100%;
    top: 41%;
    position: absolute;
  }
}

.pipeline-arrow {
  font-size: 20px;
  margin: 0 10px;
  width: 50px;
  height: 50px;
}
.step-bottom {
  margin-top: 30px;
  .tooltip-content {
    border-bottom: 2px solid red;
  }
}

.bottom-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: absolute;
  bottom: -60px;
  left: 50%; /* Center the bottom-button */
  transform: translateX(-50%);
  width: 100px;
}

.table-extraction-box {
  border-top: 2px solid rgb(192, 192, 192);
}

.stepBtn {
  margin-right: 5px;
  padding: 0px 10px;
  height: 28px;
  background-color: #5055cb;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-family: "poppins", sans-serif;
}
.GoBtn {
  margin-right: 5px;
  padding: 0px 10px;
  height: 28px;
  background-color: #2e6820;
  color: #fff;
  border-radius: 5px;
  border: none;
  font-family: "poppins", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.HeaderContainer {
  display: flex;
  align-items: center;
  padding-inline: 20px;
  margin-top: 20px;
  margin-left: 10px;
}

.CurationText {
  margin-right: 10px;
  /* Any additional styles for the text */
}

.all-btn-disabled {
  background-color: #c7c8cc;
  color: #666;
}
