.DeleteBox {
  display: flex;
  flex-direction: column;
  gap: 50px;
  .btnBox {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
