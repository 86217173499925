.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.card {
  width: 180px;
  height: 220px; /* Ensure height is applied */
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.card .card-inner {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card .card-front,
.card .card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  border-radius: 15px;
}

.card .card-front {
  background: linear-gradient(135deg, #8fc9ef, #4d53e0);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.2rem;
  text-align: center;
  padding: 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.card:hover .card-front {
  background: linear-gradient(135deg, #ff5f49, #fe9653);
}

.card .card-back {
  background: linear-gradient(135deg, #7389ff, #5bd9ff);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateY(180deg);
  font-size: 1rem;
  padding: 20px;
}

.card .card-content {
  text-align: center;
}

