.table-container {
  display: flex;
  justify-content: space-between;
}

.left-table,
.right-table {
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  padding: 30px;
  /* margin: 10px; */
  margin-right: 200px;
}

table {
  border-spacing: 1; /* Use border-spacing instead of border-collapse */
  width: 100%;
}

th, td {
    border: 1px solid; 
  padding: 10px;
  text-align: left;
  /* width: 120px; */
}

/* th {
  background-color: #f2f2f2;
}
tr {
    border: 1px solid #140707;
  } */

  .table-header{
    display: flex;
    justify-content: space-between;
  }

  .json-button{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .modal-box{
        position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    /* background-color: var(--bs-modal-bg); */
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
  }
  .cls-btn-class{
   display: flex;
    justify-content: flex-end;
  }

  .close-button{
      /* position: fixed; */
   
       font-family: 'IBM Plex Sans',sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 10px;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    cursor: pointer;
    background: #fff;
    border: 1px solid #DAE2ED;
    color: #1C2025;
  }