/* DynamicForm.css */
.dynamic-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
    background-color: #f9f9f9;
    // overflow-y: scroll;
    // padding: 2%;
    // margin-top: 10px;
   
}

.dynamic-form-wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    max-height:65vh;
    overflow-y: scroll;
}

.dynamic-form-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #333;
}

.dynamic-form-group {
    margin-bottom: 15px;
   
}

.dynamic-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.dynamic-form-group input,
.dynamic-form-group select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.dynamic-form-group input:focus,
.dynamic-form-group select:focus {
    border-color: #007bff;
    outline: none;
}

.dynamic-form-group-description {
    margin-top: 5px;
    font-size: 12px;
    color: #777;
}

.dynamic-form-required label::after {
    content: '*';
    color: red;
}

.dynamic-form-input-disabled {
    background-color: #f5f5f5;
    color: #888;
    cursor: not-allowed;
}

.dynamic-form-group.disabled label {
    color: #888;
}

.dynamic-form-button-container {
    display: flex;
    justify-content: center;
 
    width: 50%;
    margin-left: 45%;
}

.dynamic-form-button {
    padding: 8px 32px;
    background-color: #4d53e0;
    color: #fff;
    border-radius: 7px;
    border: none;
    font-family: "poppins", sans-serif;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
        rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
        rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}