.keyword-generation-container{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding-top: 20px;
  box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
  /* margin-top: 50px; */

}

.diversity-box , .Top-N-box ,.keyphrase-box ,.candidate-box{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    padding: 20px;
    margin-bottom: 25px;
    gap: 10px;
    width: 80%;
    border-radius: 10px;
}
 .keyword-configrations{
   /* width: 80%; */
   /* display: flex; */
   /* justify-content: space-between ; */
 } 

.diversity , .Top-N , .keyphrase , .candidate{
    display: flex;
    justify-content: space-between; 
}

/* label {
  display: block;
  color: #555;
  font-weight: 600;
} */

.KeyWord-Input, .Keyphrase_input , .diversity-input , .candidate-input {
     width: 35%;
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 12px;
}

.maxsum-checkbox {
  display: flex;
  justify-content: flex-end; 
  margin-top: 10px;
  margin-right: 40px;
  gap: 5px;
}

.Mmr-checkbox{
   display: flex;
  justify-content: flex-end; 
  margin-top: 10px;
  margin-right: 70px;
  gap: 5px;
}


.submit-btn{
    margin-top: 20px;
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-right: 50px;
}

@media (max-width: 600px) {
  .keyword-generation-container {
    padding: 1rem;
  }
}