.btnBox {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
.folderInput {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
