/* Components/Toggler.module.css */
.dynamic-form-toggler {
  display: inline-block;
  width: 40px;
  height: 20px;
  position: relative;
}

.dynamic-form-toggler input {
  opacity: 0;
  width: 0;
  height: 0;
}

.dynamic-form-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.dynamic-form-slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  border-radius: 50%;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}

.dynamic-form-toggler input:checked + .dynamic-form-slider {
  background-color: #2196f3;
}

.dynamic-form-toggler input:checked + .dynamic-form-slider:before {
  transform: translateX(20px);
}