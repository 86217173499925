

  /* Node Container Styles */
  #modal-modal-description{
    height: 84vh;
  }

  .node-container {
    position: relative;
    width: 230px;
    height: 150px;
    transition: all 0.3s ease;
  }

  .token-container:hover {
    width: 100%;
    height: 423px;
    z-index: 1; /* Ensure the expanded node appears on top */
    overflow-y: scroll;
  }

  .token-container{
    width: 130px;
  }

  .parent-token-container {
    width: 130px;
    height: 150px;
    transition: all 0.3s ease;
  }
  /* 
  .node-container:hover {
    width: 100%;
    height: 423px;
    z-index: 1; 
  }
  
  /*
 
*/
   /* .apextree-node:hover {
    width: 100%;
    height: 423px;
    z-index: 100;
  }  */

  .parent-token-container:hover {
    width: 1120px;
    height: 423px;
   
  }

  /* Tooltip Content Styles */
  .tooltip-content {
    /* visibility: hidden; */
    display: none;
    background-color: #fff;
    color: #000;
    text-align: left;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 99%; /* Adjust width as needed */
    font-size: 14px;
  }
  .token-container:hover .tooltip-content {
    display: block;
    border-radius: 20px;
  }

.tooltip-content table {
  width: 100%;
  border-collapse: collapse;
}

.tooltip-content th, .tooltip-content td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.tooltip-content th {
  background-color: #f2f2f2;
  text-transform: capitalize;
  font-weight: 600;
  /* color: black; */
}

/* String Tooltip Styles */
.node-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.node-tokens {
  font-weight: bold;
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: center;
}

.node-image {
  width: 50px;
  height: 50px;
}

.node-count {
  font-weight: 700;
 
  font-size: 2rem;
}

.node-name {
  font-weight: bold;
  font-family: Arial, sans-serif;
  font-size: 16px; 
  /* border-top: 1px solid black; */
  width: 100%;
  text-align: center;
}
.tooltip-table {
  width: 100%;
  border-collapse: collapse;
}

.tooltip-row.header {
  background-color: #f2f2f2;
  font-weight: bold;
}

.tooltip-row.total {
  font-weight: bold;
  background-color: #f4f4f4;
}

.tooltip-column {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.tooltip-column:last-child {
  text-align: right;
}

.tooltip-row.total {
  font-weight: bold;
  background-color: #f4f4f4; }

.tooltip-header {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid grey;
}

.filter-container{
  border: 1px solid grey;
  border-radius: 5px;
  padding-inline: 7px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.filer-icon{
  height: 15px;
}

.totals-row {
  background-color: #f0f0f0; /* Light gray background */
  font-weight: bold; /* Make text bold */
}

/* Hidden class to hide the popup */
/* .hidden {
  display: none;
} */

/* Popup container */
.filter-popup {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Popup content */
.filter-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

/* Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

/* Other styles for filter options, buttons, etc. */
.filter-option {
  margin-bottom: 10px;
}
