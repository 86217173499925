.auth-page {
  height: 100%;
   overflow: hidden;

  .left-column {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $base-light-gray;
    height: 100%;
    margin-top: 25px;
  }

  .right-column {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: $white;

    @media (max-width: breakpoint-max(md)) {
      display: none;
    }
  }
}

.widget-auth {
  width: 525px;
  box-shadow: 4px 4px 16px 0 rgba(77, 83, 224, 0.2);

  @media (max-width: breakpoint-max(xs)) {
    width: 100vw;
  }

  .auth-header {
    font-size: $headline-large;
    font-weight: $font-weight-semi-bold;
  }

  .logo-block {
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-left: 12px;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
    }
  }

  a {
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
  }
}

.auth-info {
  padding: 18px 21px;
  border-radius: .5rem;
  background: $body-bg;
  color: $base-dark-gray;
}

.form-control {
  color: $base-dark-gray;
}

.dividing-line {
  display: flex;
  flex-direction: row;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}

.dividing-line:before,
.dividing-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid $base-middle-gray;
  margin: auto;
}

.social-label {
  margin-right: 1rem;
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
  color: $base-dark-gray;
}

.socials {
  a {
    margin: 0 .5rem;
  }
}

