.Json-Container{
    display: flex;
    padding: 1%;

}

.Json-file-container{
    flex: 0 0 30%;
    // display: flex;
    // justify-content: flex-end;
    // flex-direction: column;
    // position: relative;
    // align-items: center;
    // justify-content: center;
    padding: 5px;
}
.json-seprater-line{
       border-left: 1px solid #cdcdcd;
    margin-inline: 5px;
    height: 400px;
    width: 1px;
}
.json-form-container{
    padding: 1%;
    margin-top: 5px;
    margin-right: 10px;
    width: 70%;
    justify-content: center;
}
.raft-json-form-container{
      padding: 1%;
    margin-top: 5px;
    margin-right: 10px;
    width: 70%;
    justify-content: center;
     height: 53vh;
    overflow-y: scroll;
}
.json-upload-button-container{
     position: relative;
   display: flex;
    justify-content: center;
    align-items: center;
    // opacity: 0.7;
    border: 1px dashed grey;
    border-radius: 5px;
    padding: 15px;
    gap: 10px;
   margin-bottom: 10px;
}

.json-upload-button{
        // font-family: 'IBM Plex Sans',sans-serif;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 10px;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    cursor: pointer;
    background: #fff;
    border: 1px solid #DAE2ED;
    color: #1C2025;
}
.json-save-button{
    // margin-top: 5px;
    display: flex;
    justify-content: flex-end;
}