/* Embeddings.css */

.chunks-embeddings {
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  width: 98%;
  min-height: 71vh;
  margin-left: 10px;
}

.Embedding-input-section {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  
}

.Embedding-input-section label {
  margin-right: 10px;
}

.Embedding-input-section input {
  margin-right: 20px;
     width: 60px;
    padding-left: 8px;
    height: 26px;
    border: 2px solid #ddd;
    border-radius: 5px;
}

.Embedding-input-section button {
  margin-top: 10px;
 
}

.chunks-section {
  margin-top: 20px;
}

.Embedding-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.Embedding-header .Button {
  margin-left: auto;
 
}


/* Responsive styles */
@media (max-width: 768px) {
  .Embedding-input-section {
    flex-direction: column;
    align-items: flex-start;
  }

  .Embedding-input-section label, 
  .Embedding-input-section input,
  .Embedding-input-section button {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .Embedding-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .Embedding-header span {
    margin-bottom: 10px;
  }

}

@media (max-width: 480px) {
  .Embedding-header {
    align-items: stretch;
  }

  .Embedding-header span {
    margin-bottom: 5px;
  }



  .Embedding-input-section label,
  .Embedding-input-section input,
  .Embedding-input-section button {
    width: 100%;
  }
}

.chunkbox{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    display: flex;
    padding: 20px;
    margin-bottom: 25px;
    margin-top: 15px;
    gap: 10px;
    width: 60%;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
}

.HorizontalLine{
  border-left: 2px solid #cdcdcd;
  margin: 5px;
      /* margin: 3rem 0 */
  height: 2px;
  width: 100%;
  
}
.Embedding-top-content{
  display: flex;
  justify-content: space-between;
   margin-right: 30px; 
}

.copy-content{
  display: flex;
  justify-content: end;
 
}

.copy-button{
      margin: 10px 50px 10px;
}

.close-button{
      display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(6, 69, 120);
}

.embedding-output-box{
  margin-top: 20px;
  display: flex;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
  /* margin-inline: 25px; */
}
.embedding-chunks-box{
  width: 30%;
  min-width: 30%;
  max-width: 30%;
}
.chunk-output-embedding-container{
  /* width: 30%; */
}
.chunk-preview-in-embedding{
  width: 40%;
}

.no-graph-title{
  font-weight: 600;

}
.embedding-chart{
  width: 60%;
  /* border: 2px solid grey; */
  border-radius: 10px;
  /* height: 50vh; */
  padding-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.embeddings-ouput-control-box{
  width: 40%;
  height: 72vh;
  overflow-y: scroll;

  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.embeddings-output-title{
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #acacac;

}
.embeddings-control{
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-inline: 10px;
}
.embedding-output-btn{
  display: flex;
  gap: 5px;
  /* padding-bottom: 19px; */
  /* border-bottom: 2px solid gainsboro; */
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}
.download-icon{
  border: 1px solid  grey;
}
.embedding-dimension-input{
}

.token-consumption-dount{
  height: 100%;
  width: 100%;
  border: 1px solid black;
  font-size: 24px;
  font-weight: 600;
}