.dcui-mainContent {
  transition: filter 0.3s ease-in-out;
}

.dcui-blurred {
  filter: blur(3px);
  opacity: 0.5;
  pointer-events: none;
}
.dcui-sidebarOpen {
  margin-right: 500px;
}

.dcui-avatarContainer {
  width: 80px;
  height: 80px;
  margin: 0 auto 1.5rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e0e0e0;

  .dcui-avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.dcui-sidebar {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;

  &.dcui-open {
    right: 0;
  }

  .dcui-sidebarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;

    h5 {
      margin: 0;
    }

    .dcui-closeBtn {
      background: none;
      border: none;
      cursor: pointer;
    }
  }

  .dcui-searchInput {
    width: calc(100% - 2rem);
    padding: 0.5rem;
    margin: 1rem;
  }

  .dcui-serviceList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    flex-grow: 1;

    li {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: #f5f5f5;
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 0.5rem;
      }
    }
  }

  .dcui-Connectorsnextbutton {
    display: flex;
    flex-direction: row-reverse;
    padding: 1rem;
    border-top: 1px solid #e0e0e0;
  }
}
.dcui-serviceConnectionContainer {
  width: 50%;
  margin: 2rem auto;
  text-align: center;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: margin-right 0.3s ease;
}

.dcui-connectorsButton {
  margin-right: 10px;
  padding: 8px 32px;
  background-color: #4d53e0;
  color: #fff;
  border-radius: 7px;
  border: none;
  font-family: "poppins", sans-serif;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}

// New styles for the table and pagination
// .dcui-tableContainer {
//     //  margin-top: 1rem;
//     padding: 1rem;
//     background-color: #ffffff;
//     border-radius: 8px;
//     box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//     width: 50%;
//     margin-bottom: 20px;
//     position: relative;
//   transition: margin-right 0.3s ease;
//     transition: filter 0.3s ease-in-out
// }
.dcui-tableContainer {
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 20px;
  transition: width 0.3s ease;
  position: relative;
}
.dcui-table {
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }

  th {
    background-color: #f5f5f5;
    font-weight: bold;
  }

  tr:last-child td {
    border-bottom: none;
  }
}

.dcui-pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  .page-item {
    margin: 0 0.25rem;

    .page-link {
      color: #4d53e0;
      border-color: #4d53e0;

      &:hover {
        background-color: #4d53e0;
        color: #ffffff;
      }
    }

    &.active .page-link {
      background-color: #4d53e0;
      border-color: #4d53e0;
    }
  }
}

// Media queries for responsiveness
@media (max-width: 1200px) {
  .dcui-serviceConnectionContainer {
    width: 60%;

    &.dcui-sidebarOpen {
      margin-right: 0;
      transform: translateX(-200px);
    }
  }
}

@media (max-width: 992px) {
  .dcui-serviceConnectionContainer {
    width: 70%;

    &.dcui-sidebarOpen {
      transform: translateX(-100px);
    }
  }
}

@media (max-width: 768px) {
  .dcui-serviceConnectionContainer {
    width: 80%;
    padding: 1.5rem;

    &.dcui-sidebarOpen {
      transform: none;
      opacity: 0.3;
    }

    .dcui-sidebar {
      width: 100%;
      right: -100%;
    }
  }

  .dcui-table {
    font-size: 0.9rem;

    th,
    td {
      padding: 0.5rem;
    }
  }

  .dcui-pagination {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .dcui-serviceConnectionContainer {
    width: 95%;
    padding: 1rem;

    .dcui-avatarContainer {
      width: 60px;
      height: 60px;
    }
  }

  .dcui-table {
    font-size: 0.8rem;

    th,
    td {
      padding: 0.4rem;
    }
  }

  .dcui-pagination {
    font-size: 0.8rem;
  }
}
.dcui-tableBox {
  display: flex;
  justify-content: center;
  position: relative;
  transition: margin-right 0.3s ease, filter 0.3s ease-in-out;
  width: 50%;
  margin: 0.5rem auto;
  padding: 1rem;
  border-radius: 8px;
  position: relative;
  transition: margin-right 0.3s ease;

  &.dcui-TablesidebarOpen {
    margin-right: 500px; // Match the sidebar width
    filter: blur(3px);
    opacity: 0.5;
    pointer-events: none;
  }
}
