.metadataContainer {
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 99%;

  min-height: 60vh;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 10px;

  .metaTitle {
    margin-top: 10px;
    margin-left: 20px;
    margin-bottom: 25px;
  }
  .metadatFormView {
    box-shadow: rgba(0, 0, 0, 0.75) 0px 2px 8px;
    width: 50%;
    height: 72vh;
    overflow: hidden;
    border-radius: 10px;
    // border-top-right-radius: 10px;
    // border-bottom-right-radius: 10px;
  }

  .MetadataPreview {
    display: flex;
    gap: 20px;
    min-height: 60vh;
    margin-top: 24px;
    padding: 10px;
    width: 100%;
    .chooseModelCheckBox {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 45px;
      padding-inline: 20px;
      border-bottom: 1px solid grey;
    }
  }
  .outputContentTitle {
    padding-inline: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 600;
  }
}

.metadataContainer ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.metadataContainer ::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.metadataContainer ::-webkit-scrollbar-thumb {
  background-color: #9b9b9b;
  border-radius: 10px;
}
.KeywordoutputChunkBox {
  border: 2px solid #dadada;
  border-radius: 10px;
  width: 95%;
  height: auto;
  font-size: 14px;
  padding: 5px;

  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}
.topicOutputChunkBox {
  border: 2px solid #dadada;
  border-radius: 10px;
  width: 95%;
  height: auto;
  font-size: 14px;
  padding: 5px;

  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 5px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  overflow: hidden;
}
.chunkTitlePara {
  border: 2px solid rgb(215, 215, 215);
  display: inline-block;
  border-radius: 5px;
  padding: 3px;
  background-color: #f5f5f5;
}

.verticalLine {
  border-left: 2px solid #808080;
  height: 60vh;
  margin: 4px 6px;
  position: absolute;
  left: 48.5%;
}
.titleverticalLine {
  border-left: 2px solid #808080;
  height: 30px;
  margin: 6px;
  margin-left: 12px;
  // position: absolute;
  // left: 48.5%;
}
.chunkOutputBox {
  // height: 60vh;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  position: relative;
}
.noMetadataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
}

.noMetadataImage {
  max-width: 30%;
  height: auto;
  margin-bottom: 1rem;
}
