.editableTableMainContainer {
  display: flex;
  transition: transform 0.1s ease;

  gap: 20px;
  .tableListbox {
    // width: 2%;
    overflow-x: hidden;
    // overflow-y: scroll;
    display: flex;
    gap: 5px;
    flex-direction: column;
    transition: width 0.1s ease;
    // align-items: end;
    // background-color: #e3e1d9;
    // background-color: #faf9f7;

    // width: 15%;
    .closeIcon {
      // text-align: end;
      background-color: #ccc;
      border: 1px solid #ccc;
      border-radius: 50%;
    }
    .tableList {
      padding: 5px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      list-style: none;
      position: relative;
      margin-bottom: 5px;
      // border-bottom: 2px solid grey;
      overflow: hidden;
      width: 200px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      transition: background-color 0.1s ease;
      &:hover {
        // background-color: #fff8e3;
        background-color: #c7c8cc;
      }
      .tableItemBox {
        display: flex;
        gap: 10px;
        text-wrap: nowrap;
        .tableItemNumber {
          border-radius: 50%;
          margin-left: 5px;
          // border: 2px solid #a3b6d2;
          // width: 26px;
          // width: 8px;
          text-align: center;
          background-color: #efefef;
          // height: 27px;
          // height: 8px;
        }
        .tableItem {
          text-decoration: none;
          color: black;
        }
      }
    }
    .activeList {
      // background-color: #e6a4b4;
      background-color: #b4b4b8;
      .tableItem {
        text-decoration: none;
        color: #fff;
      }
    }
  }
  .tableContainer {
    overflow-y: scroll;
    // scroll-behavior: smooth;
    // scroll-behavior: smooth;
    min-height: 70vh;
    height: 70vh;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 1px 5px;
    gap: 10px;
    // width: 85%;
    //   justify-content: space-between;
    //   border: 2px solid red;
    margin-bottom: 10px;
  }
  .tableContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  .tableContainer::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }
  .tableContainer::-webkit-scrollbar-thumb {
    background-color: #9b9b9b;
    border-radius: 10px;
  }

  .tableWrapper {
    // display: flex;
    // justify-content: space-between;

    scroll-behavior: auto;
    width: 100%;
    border: 2px solid #bbbbbb;
    padding: 10px;
    margin: auto;
    margin-bottom: 10px;
    border-radius: 5px;
  }
}
.drawerToggleButton {
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.editableTableMainContainer {
  display: flex;
  transition: all 0.1s ease-in-out;
}

.tableListbox {
  width: 210px;
  // width: 246px;
  overflow: hidden;
  transition: width 0.1s ease-in-out;
}

.editableTableMainContainer.drawerOpen .tableListbox {
  width: 2%;
}

// .tableContainer {
//   // width: 85%;
//   transition: width 0.1s ease-in-out;
// }

.editableTableMainContainer.drawerOpen .tableContainer {
  width: 100%;
}

.tableList {
  // opacity: 1;
  // transform: translateX(0);
  // transition: all 0.1s ease-in-out;
}

.editableTableMainContainer.drawerOpen .tableList {
  opacity: 0;
  display: none;
  // transition: all 0.1s ease-in-out;
}

.tableItemBox {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

// .editableTableMainContainer.drawerOpen .tableItemBox {
//   opacity: 0;
//   transform: translateX(-20px);
// }

.tableItemNumber,
.tableItem {
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.json-button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modal-box {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  /* background-color: var(--bs-modal-bg); */
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.clsbtnclass {
  display: flex;
  justify-content: flex-end;
}

.closebutton {
  /* position: fixed; */

  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 10px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dae2ed;
  color: #1c2025;
}

.viewJsonButton {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 10px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dae2ed;
  color: #1c2025;
}
.spreadsheetBox {
  border: 2px solid #c8c8c8;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}

.json-button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.modal-box {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  /* background-color: var(--bs-modal-bg); */
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}
.clsbtnclass {
  display: flex;
  justify-content: flex-end;
}

.closebutton {
  /* position: fixed; */

  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 10px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dae2ed;
  color: #1c2025;
}

.viewJsonButton {
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 10px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dae2ed;
  color: #1c2025;
}
.tableTitleBox {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
