.container {
  height: 500px;
  overflow: hidden;
  position: relative;
}

.imageWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  position: relative;
}

.chartImage {
  max-height: 100%;
  max-width: 100%;
  transform-origin: center;
}

.titleBox {
  border-bottom: 1px solid #acacac;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-right: 10px;
}
.graphicalTitle {
  font-weight: 600;
  padding: 10px;
}
.noChartAvailableBox {
  display: flex;
  height: 90%;
  width: 100%;
  background-color: #fff;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
}
.noGraphTitle {
  font-weight: 600;
  padding-inline: 10px;
  text-align: center;
}
.prevControl {
  border: none;
  /* border-radius: 107%; */
  border-radius: 50%;
  height: 35px;
  margin-right: 5px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background: #fff;
  /* height: 20%; */
  /* width: 8%; */
}
