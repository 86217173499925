@import "../../styles/helpers";

.tableTitle {
  display: flex;
  justify-content: space-between;
  padding: 24px 24px;

  img {
    margin-left: 16px;
  }
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}

.transactionTitleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 24px 13px 16px;
}

.widgetContentBlock {
  margin: 0 24px 24px;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 13px 24px 13px 16px;
  border-radius: 8px;
  background-color: $base-light-gray;
}

.image {
  width: 32px;
  height: 32px;
}

.checkboxCol {
  width: 5%;
}
.ColBox {
  font-weight: 600;
}

.nameCol {
  width: 15%;
}

.actionButtons {
  display: flex;
  justify-content: start;
  gap: 20px;
  align-items: center;
}

.nodatafoundbox {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  gap: 20px;
  align-items: center;
}
.cardMainContainer {
  display: flex;
  justify-content: space-between;
}

.cardContainer {
  // height: 133px;
  // width: 30%;
  // padding: 15px;
  // border-radius: 10px;
  // // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  // box-shadow: rgba(0, 0, 0, 0.1) 4px 4px 3px;
  // margin: 20px;
  height: 133px;
  width: 30%;
  padding: 15px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 6px 8px 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-inline: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.cardContainer:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 20px;
}

.cardContainer1 {
  // background-color: #caf4ff;
  background-color: #fff;
  border-left: 7px solid #105ec0;
}

.cardContainer2 {
  background-color: #e0fbe2;
  background-color: #fff;
  border-left: 7px solid #0a6847;
}

.cardContainer3 {
  background-color: #ffefef;
  background-color: #fff;
  border-left: 7px solid #7aba78;
}

.cardContainer4 {
  background-color: #fffbda;
  background-color: #fff;
  border-left: 7px solid #abab00;
}

// .cardContainerText {
// }

.cardContainerText1 {
  color: #105ec0;
}
.cardContainerText2 {
  color: #0a6847;
}
.cardContainerText3 {
  color: #7aba78;
}
.cardContainerText4 {
  color: #abab00;
}

.contentContainer {
  display: flex;
  gap: 10px;
}

.editIconbox {
  border-right: 2px solid #4d53e0;
  padding-right: 20px;
  color: #327aff;
  cursor: pointer;
  .actionIcon {
    height: 22px;
    margin-right: 5px;
  }
}
.actinImage {
  margin-right: 5px;
}
.deleteIcon {
  color: red;
  // border-right: 2px solid #4d53e0;
  padding-right: 20px;
  cursor: pointer;
  .actionIcon {
    height: 22px;
    margin-right: 5px;
  }
}

.truncateText {
  max-width: 200px; /* Adjust the max-width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-width 0.3s ease, text-overflow 0.3s ease;
}
.truncateText:hover {
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}
