
.radio-box{
   width: 50%;
  /* justify-content: space-around; */
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.topic-generation-title{
  /* margin-Left: "20px",
  marginBottom: "20px",
  display: "flex",
  alignItems: "center", */
  margin:20px ;
  display: flex;
  align-items: center;
}