.meta-form-container {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  border-radius: 10px;
  //   padding-inline: 20px;
  //   padding-top: 10px;
  max-height: 34vh;
  background-color: #fff;
  padding-bottom: 30px;
  width: 98.5%;
  //   height: 20vh;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
  margin-inline: 10px;
}

.meta-form-container:hover {
  //   height: 100%;
  // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  align-items: start;
  max-height: 60vh;
  overflow-y: scroll;
}

.meta-form-box {
  //   display: flex;
  //   align-items: center;
  //   margin-top: 10px;
}

.keyword-generation-inputs-box {
  padding-bottom: 20px;
  padding-inline: 30px;
}
.keyword-generation-inputs {
  display: flex;
  align-items: center;
  gap: 50px;
  width: 100%;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // padding-inline: 20px;
  flex-wrap: nowrap;
}
.meta-form-checkbox {
  /* border-bottom: 2px solid grey; */
  display: flex;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 50;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-inline: 20px;
}
.meta-form-keyword-configrations {
  width: 35%;
}
.topic-generation-box {
  padding-inline: 30px;
  padding-top: 10px;
}
.topic-generation-subtitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  text-wrap: nowrap;
  display: flex;
}
.topic-generation-checkbox {
  width: 35%;
}
.topic-generation-checkbox-container {
  display: flex;
  gap: 20px;
}

.representation-model-input-box,
.embedding-model-input-box {
  display: flex;
  gap: 20px;
}
.first-row-models {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.embedding-model-container {
  margin-top: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
}
.UMAP-Model-input-box,
.HDB-Scan-input-box,
.Stop-word-input-box {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
}
