.main-container{
  box-shadow: rgba(0, 0, 0, 0.75) 0px 2px 8px;
  
  border-radius: 10px;
  /* width: 50%; */
  height: 72vh;

}
.current-content{
  font-weight: 600;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid grey;
  padding: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px; */
}
.pdfContainer {
  /* background-color: #cdcdcd; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */

  height: 70vh;

  overflow-y: scroll;
}
.pdfContainer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.pdfContainer::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.pdfContainer::-webkit-scrollbar-thumb{
  background-color: #9b9b9b;
  border-radius: 10px;
}
canvas.react-pdf__Page__canvas{
     margin-bottom: 20px;      
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
      box-shadow: rgba(0, 0,ss 0, 0.75) 0px 2px 8px;
      /* border-radius: 10px; */
}

.highlightArea{
  height: 100px ;
  position: absolute;
  width:300px ;
  /* border: 2px solid red ; */
  background: blue;
  opacity: .2;
  z-index: 100;
  left: 277.6000061035156px;
  top: 71px;
}

.extractPdf-title{
  display: flex;
  justify-content: space-between;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px; */
}
.config-title{
  margin-right: 5%;
}




.extractedPDFContainer{
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 70vh;
  overflow-y: scroll;
  width: 100%;
}

.Extract-pdf-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(216, 216, 216, 0.3);
  background-color: #f5f5f5;
}
.Extract-pdf-box::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.Extract-pdf-box::-webkit-scrollbar-thumb{
  background-color: #808080;
  border-radius: 10px;
}

.Extract-pdf-box{
  /* border: 2px solid red; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* box-shadow: rgba(0, 0, 0, 0.75) 0px 2px 8px; */
  /* border-radius: 10px; */
}
.extractPdf-title{
  font-weight: 600;
  /* margin-bottom: 10px; */
  border-bottom: 1px solid #acacac;
  padding: 10px;
}
.extractPdf-title.reduced-padding {
    font-weight: 600;
  
  border-bottom: 1px solid #acacac;
  padding: 0px;
}


.pdfViewer::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.pdfViewer::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.pdfViewer::-webkit-scrollbar-thumb{
  background-color: #9b9b9b;
  border-radius: 10px;
}

.react-pdf__Page__textContent{
  display: none !important;
}
.react-pdf__Page__annotations {
  display: none;
}
#pdf-page-wrapper{
  margin: 0;
}
#header-bar{
  display: none;
}
.wordRender{
  height: 60vh;
  box-shadow: rgba(0, 0, 0, 0.75) 0px 2px 8px;
  border-radius: 10px;
}
.document-viewer{
  height: 100%;
  width: 95%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border-radius: 10px; */
  /* box-shadow: rgba(0, 0, 0, 0.75) 0px 2px 8px; */
}
.control-box{
  display: flex;
  align-items: center;
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  justify-content: space-between;
  /* margin-right: 10px; */
  height: 45px;
}
.image-name{
  font-size: 14px;
  margin-left: 5px;
}
.controls{
  display: flex;
  align-items: center;
  gap: 10px;
}

.prev-control{
  border: none;
  /* border-radius: 107%; */
  border-radius: 50%;
  height: 35px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  background: #fff;
  /* height: 20%; */
  /* width: 8%; */
}

.image-render{
  margin-top: 20px;
  margin-bottom: 20px;
  padding-inline: 5px;
  height: 100%;
  width: 100%;
 box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  transform-origin: center center;
}

.last-btn{
  margin-right: 10px;
}

.current-audio {
  /* height: 15vh; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  height: 25%;
  /* margin: 15px 5px; */
}

.AudioPlayer-container {
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* margin: 20px auto; */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
 
  padding: 10px;
}

.AudioPlayer-audio {
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px;
  /* border-bottom: 1px solid grey; */
}

.AudioPlayer-input {
  margin: 5px 0;
  /* gap: 10px; */
 
  padding: 10px;
}

.AudioPlayer-description {
  font-size: 1rem;
  padding: 5%;
  height: 75%;

  text-align: justify;
   overflow-y: scroll;
}

.rhap_container {
  background: #f7f7f9;
  box-shadow: none !important   ;
}

.rhap_main-controls-button {
  display: block;
}

.rhap_main-controls-button svg {
  color: #3f5bd6;
  width: 100%;
  height: 100%;
}
.rhap_progress-filled,
.rhap_progress-indicator {
  background-color: #3f5bd6 !important;
}

.rhap_button-clear.rhap_volume-button {
  color: #3f5bd6 !important;
}

.rhap_volume-bar, .rhap_volume-indicator {
  background-color: red;
}

.AudioPlayer-description ::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.AudioPlayer-description ::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.AudioPlayer-description ::-webkit-scrollbar-thumb{
  background-color: #9b9b9b;
  border-radius: 10px;
}

.file-name-box{
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}
.chunk-heade-container{
  display: flex;
  width: 100%;
  justify-content: space-between;

}
.chunk-header-pagination{
  display: flex;
  gap: 10px;
  align-items: center;

}
.tab-label .MuiTab-wrapper {
  text-transform: capitalize; 
  /* font-size: 14px;  */
}
.chunk-header-container{
  display: flex;
  width: 100%;
  margin-right: 10px;
  justify-content: space-between;
}