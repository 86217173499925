.investment-header{
 display: flex;
  /* align-items: center; */
  align-items: flex-start; 
  justify-content: space-between;
  padding: 16px;
  margin: 8px 0;
  border: 1px solid #e0e0e0;
   text-align: center;
  border-radius: 8px;
 
}

.investment-card {
  display: flex;
  /* align-items: center; */
  align-items: flex-start; 
  justify-content: space-between;
  padding: 16px;
  margin: 8px 0;
  border: 1px solid #e0e0e0;
  text-align: center;
  border-radius: 8px;
  border-left: 7px solid #105ec0;
  position: relative;
  transition: all 0.3s ease;
}


.column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* align-items: center; */
  justify-content: start;
}

.type1 {
   width: 20%;
    /* margin-right: 25px; */
    text-align: right;
}

.type2{
    width: 20%;
    /* margin-right: 25px; */
    text-align: right;
}

.investment-amt {
  width: 20%;
  text-align: right;
  /* margin-right: 25px; */
}

.market-value {
  width: 20%;
  text-align: right;
  /* margin-right: 25px; */
}

.overall-gl {
  width: 20%;
  text-align: right;
  /* margin-right: 25px; */
}

.todays-gl {
  width: 20%;
  text-align: right;
  /* margin-right: 25px; */
}

.action {
  width: 20%;
  text-align: center;
   margin-left: -200px;
}
.investment-card.hovered {
  /* background-color: #dcdcdc; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.investment-card + .investment-card {
  margin-top: 8px;
  transition: margin-top 0.3s ease;
}

.investment-card.hovered + .investment-card {
  margin-top: 90px; /* Adjust this value as needed */
  /* border-radius: 8px; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}


.investment-header {
  background-color: #f5f5f5;
  font-weight: bold;
 
}
.hover-info {
   display: flex; 
   /* flex-direction: column; */
  position: absolute;
  top: 100%;
  gap: 10px;
  font-size: 12px;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
}
.card-info{
      display: flex;
    flex-direction: column;
    width: 50%;
    align-items: start;
    
}
.dropdown-toggle {
  
  /* margin-top: 10px;  */
  padding: 8px 16px;
  border: none;
  background-color: #105ec0;
  color: white;
  border-radius: 4px;
  cursor: pointer;
   transition: margin-top 0.3s ease;
}

.dropdown-info {
      display: flex;
    align-items: flex-start;
    /* flex-direction: column; */
    position: absolute;
    top: 100%;
    gap: 10px;
    font-size: 12px;
    left: 0;
    width: 85%;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    z-index: 10;
    margin-left: 15%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 
}
 .dropdown{
  width: 100%;
   display: flex;
   justify-content: start;
  margin-left: 5%;
   margin-right: 5%;
 }
 .drop-child{
  width: 100%;
  display: flex;
   justify-content: start;
 }

/* .icon {
   width: 24px; 
  height: 24px;
  margin-right: 8px;
  border-radius: 50%;
} */

.icon.workspace-1 { background-color: #36cfc9; }
.icon.Workspace-1 { background-color: #ffec3d; }
.icon.Workspace-1 { background-color: #8c8c8c; }
.icon.Workspace-1 { background-color: #722ed1; }

.type-text {
  font-weight: bold;
}

.action-button {
  padding: 8px 16px;
  background-color: #1890ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.action-button:hover {
  background-color: #40a9ff;
}

.table-striped tbody tr {
  margin-bottom: 5px; /* Adds gap between rows */
  border-radius: 10px; /* Adds semi-circular borders */
  overflow: hidden; /* Ensures rounded corners are visible */
  border: 1px solid #ddd; /* Adds a border to each row */
  background-color: #fff; /* Adds background color to each row */
}

.table-hover tbody tr:hover {
  background-color: #f5f5f5;
   border-radius: 10px; /* Adds semi-circular borders */
  overflow: hidden; /* Ensures rounded corners are visible */
  border: 1px solid #ddd; /* Adds a border to each row */
  background-color: #fff; /* Adds background color to each row */
}

.table tbody tr td {
  padding: 12px 15px; /* Adds padding inside cells */
}

.pagination-borderless .page-link {
  border: none;
  color: #327aff;
}

.pagination-borderless .page-item.active .page-link {
  background-color: #327aff;
  border-radius: 50%;
}
