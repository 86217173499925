.saveBtn {
  margin-right: 10px;
  padding: 8px 32px;
  // width: 100px;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #4d53e0;
  // background-color: rgb(52, 152, 219);
  color: #fff;
  border-radius: 7px;
  border: none;
  font-family: "poppins", sans-serif;
  // font-size: 0.875rem;
  // font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
}
.otherBtn {
  margin-right: 10px;
  padding: 0.5rem 2rem;
  // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  // background-color: #4d53e0;
  // width: 100px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  background-color: rgb(199, 208, 217);
  color: #000000;
  border-radius: 7px;
  border: none;
  font-family: "poppins", sans-serif;
  // font-size: 0.875rem;
  // font-weight: 600;
}
.disabledBtn {
  background-color: #c7c8cc;
  color: #666;
  cursor: not-allowed;
}

.saveBtn:disabled,
.otherBtn:disabled {
  background-color: #c7c8cc;
  color: #666;
  cursor: not-allowed;
}
