@import "../../styles/helpers";

.widgetText {
  margin-top: 10px;
  font-size: $font-size-sm;
  font-weight: $font-size-base;
  color: $base-dark-gray;
}

.layoutContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  width: 99%;
  height: 130px;
  border: 1px dashed $violet;
  overflow-y: auto;
}

.layoutButtonsRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.layoutButton {
  width: 82px;
  height: 36px;
  background-color: #c7d0d934;
  outline: none;
  border: none;
}

.layoutButtonActive {
  background-color: $yellow;
}

.layoutText {
  font-size: $font-size-sm;
  font-weight: $font-weight-base;
  color: $violet;
}

progress {
  visibility: hidden;
}

.IngetionContainer {
  display: flex;
  position: relative;
  //  justify-content: center;
  //  align-items: center;
  // flex-direction: column;
  // margin-top: 50px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 99%;
  min-height: 71vh;
  padding: 20px;
  margin-bottom: 20px;
  overflow-y: auto;
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
  border: 1px dashed grey;
  border-radius: 5px;
  padding: 5px;
  // height: 50%;
  //  border: 1px solid #4d5052; /* Add a border to the image container */
  //  border-style:dotted ;
  //  border-radius: 5px; /* Optional: add rounded corners */
  //  padding: 5px; /* Optional: add padding inside the border */
  // box-sizing: border-box;
}

.buttonWrapper {
  position: absolute;
  top: 50%;
  left: 38%;
  // transform: translate(-50%, -50%);
}
.chooseFileButton {
  /* Add any additional styles for the button here */
  background-color: #060b81;
  color: white;
  border: none;
  padding: 5px 5px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 1px;
}
.noFilesAvailableBox.dragOver {
  border: 2px dashed #007bff;
}
.dragBox,
.dragOver {
  border: 1px dashed rgb(180, 180, 180);
}

/* Scrollbar styles for Webkit browsers */
.IngetionContainer::-webkit-scrollbar {
  width: 8px;
}

.IngetionContainer::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.IngetionContainer::-webkit-scrollbar-thumb {
  background: #888;
}

.IngetionContainer::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// .dataSourceContainer{

.dataSourceContainer {
  margin-bottom: 1.4rem;
  // display: flex;
  gap: 5%;
  align-items: center;
  .noFilesAvailableBox {
    display: flex;
    // width: 100%;
    height: 71vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //      position: relative;
    //      border: 2px dashed #ccc;
    //    border-radius: 4px;
    //   padding: 20px;
    //   text-align: center;
    //   transition: background-color 0.3s;
    //   .noFilesAvailableBox.dragOver {
    //   border-color: #000;
    //   background-color: #f0f0f0;
    // }
    .noFilesAvailableContent {
      text-align: center;
      .title {
        font-weight: 600;
        font-size: 20px;
        z-index: -1;
      }
      .initialFileInputLabel {
        // font-style: italic;
        color: #4d53e0;
        cursor: pointer;
        font-style: italic;
      }
    }
    .noFilesAvailableImage {
      height: 10px;
      // width: 200px;
    }
  }
  .choosefileBox {
    display: flex;
    gap: 20px;
    .choosefileBoxLeftSide {
      width: 30%;
      height: 300px;
      position: relative;
      border: 2px dotted #cdcdcd;
      border-right: 2px dotted #cdcdcd;
      .chooseFileImage {
        height: 10%;
        width: 10%;
        object-fit: cover;
        opacity: 0.2;
      }
      .buttonBox {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-right: 10px;
        padding: 0.5rem 1em;
        cursor: pointer;
        // box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
        //   rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
        //   rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
        // background-color: #c7d0d9;
        // border: 2px solid #4d53e0;
        color: #000000;
        color: #fff;
        border-radius: 3px;
        background-color: #4d53e0;
        // border: none;
        font-family: "poppins", sans-serif;
        .fileInputLabel {
          cursor: pointer;
        }
      }
    }
    .fileListBox {
      width: 100%;
      .fileBoxContainer {
        height: 53vh;
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar {
          width: 6px;
          background-color: #f5f5f5;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #9b9b9b;
          border-radius: 10px;
        }
        .fileBox {
          display: flex;
          gap: 15px;
        }
      }
      .fileListContainer {
        width: 100%;
        .fileListTitle {
          font-size: 18px;
          font-weight: 600;
          padding-bottom: 10px;
        }
        .contentBox {
          width: 98%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 0.5px solid #cdcdcd;
          // .srNoList {

          //   border: 2px solid grey;
          //   border-radius: 50%;
          //   padding-inline: 7px;
          //   font-weight: 600;
          //   font-size: 14px;
          // }
          .fileList {
            padding-inline: 15px;
            width: 100%;
            align-items: center;
            display: flex;
            gap: 20px;
            // .fileName {
            // }
            .fileDetails {
              display: flex;
              gap: 10px;
              align-items: center;
              .verticalLineInfileDetail {
                border-left: 2px solid grey;
                height: 12px;
              }
            }
          }
        }
      }
    }
  }
  .filname {
    font-size: 16px;

    // font-weight:600;
  }

  @media (max-width: 1190px) {
    justify-content: space-between;
  }
  @media (max-width: 970px) {
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    gap: 10px;
  }
}

.dataSourceBox {
  display: flex;
  align-items: center;
  gap: 10%;
  flex-wrap: wrap;
  padding: 20px;
  border-radius: 10px;
  width: 45%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

  .UploadBtn {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    // width: 100px;
    height: 40px;
    display: flex;
    padding-inline: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background-color: #3396d8;
    background-color: #4d53e0;
    color: #fff;
    border: none;
  }

  @media (max-width: 1190px) {
    width: 100%;
  }
  @media (max-width: 970px) {
    width: 100%;
    justify-content: space-around;
  }
  @media (max-width: 430px) {
    width: 100%;
    gap: 20px;
  }
}
.AddInputBtn {
  // padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  margin-top: 3%;
  background-color: #4d53e0;
  color: #fff;
  border-radius: 20%;
  border: none;
  font-family: "poppins", sans-serif;
}
.BtnMainContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 1px 5px; /* Adjusted padding */
  display: flex;
  gap: 10px; /* Adjusted gap */
  font-weight: 600;
  align-items: center;
  font-size: 20px; /* Adjusted font size */

  @media (max-width: 430px) {
    flex-direction: column;
    gap: 10px; /* Adjusted gap for small screens */
  }
}

.ButtonContainer {
  display: flex;
  gap: 5px; /* Adjusted gap */
  align-items: center;
  justify-content: flex-start;
  padding: 1px 5px; /* Adjusted padding */
  font-weight: 600;
  font-size: 14px; /* Adjusted font size */
}

.leftPane {
  flex: 0 0 30%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 5px;
  //  background-color: #f0f0f0; /* You can change the color as needed */
}

.rightPane {
  // flex: 0 0 69%;
  width: 70%;
  //  overflow-y: auto;
  // background-color: #d0d0d0; /* You can change the color as needed */
}

.noFilesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  // height: 250px;
  // width: 320px; // Ensure it takes up the full height of the parent
  flex-direction: column; // Align items in a column direction
}

.verticalLine {
  border-left: 1px solid #cdcdcd;
  margin-inline: 5px;
  height: 500px;
  width: 1px;
}

.dragContent {
  margin-left: 30px;
  align-items: center;
  justify-content: center;
  font-size: large;
  font-weight: 600px;
}

.fileSize {
  font-size: 12px;
}
.extractBtn {
  margin-right: 10px;
  padding: 5px 19px;
  background-color: #4d53e0;
  color: #fff;
  border-radius: 20px;
  border: none;
  width: 100px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-family: "poppins", sans-serif;
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
  &:hover {
    background-color: #3136c0;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
}
.disabledBtn {
  background-color: #c7c8cc;
  color: #666;
  cursor: not-allowed;
  &:hover {
    background-color: #c7c8cc;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
}

.openButton {
  background-color: #fff;
  color: #000;
  font-weight: 600;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  &:hover {
    background-color: #f4fdff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
}
.rightimage {
  height: 200px;
}

.nodataSourceText {
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  // height: 250px;
  // width: 320px; // Ensure it takes up the full height of the parent
  flex-direction: column;
}

.listTextBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600;
  cursor: pointer;
}
.backTextBox {
  display: flex;
  gap: 5px;
  font-weight: 600;
}
