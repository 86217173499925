/* Download.css */

.MainContainer {
  border: 2px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  height: 500px /* Set max height */
  /* overflow-y: auto; Enable vertical scroll if content exceeds height */
}
.container {
    margin: 20px;
    padding-inline: 70px;
  }
  
  .button-container {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }
  
  .button {
    /* padding: 20px; */
    margin: 5px;
    cursor: pointer;
    width: 180px;
    padding: 12px 24px; 
    background-color: #3498db;
    color: #fff;
    border-radius:5px ;
  }
  
  .download-all-button {
    background: #f46868;
    color: white;
    margin-left: 50px;  
    width: 180px;
  }

  .Download-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-left: 60px;
  }
  @media (max-width: 1090px) {
    .Download-container{
   flex-direction: column;
   gap: 30px;
    }
    .MainContainer{
  height: auto;
    }
   }

   @media (min-width: 1090px) and (max-width: 769px) {

   }
  .Download-btn-container{
    display: flex;
    /* width: 60%; */
    flex-wrap: wrap;
    gap: 30px;
    align-items: center;
  }
  .download-btn-box{

  }
  .bottomBorder{
    /* display: none; */
    border-bottom: 2px solid #e6e6e6;
    width: 100%;
    top: 40%;

    position: absolute;

  }
  .download-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 25px;
    padding-inline: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 10px;
    border: none;
    /* width: 250px; */
    width: 350px;
    /* font-weight: 600; */
    background: aliceblue; 
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }
  .download-label{
    font-weight: 600;
  }


  .d-btn{
    border: none;
    border-radius: 5px;
    padding: 7px;
    padding-inline: 12px;
    background-color: #4d53e0;
    color: #fff;
    transition: 0.3s ease;
  }
  .d-btn:hover{
    cursor: pointer;
    background-color: #32356f;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .Download-all-btn{
    
    padding: 12px;
    padding-inline: 30px;
    border-radius: 5px;
    border: none;
    width: 200px;
    font-weight: 600;
    color: #fff;
    background: rgb(24 58 139); 
    transition: .2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  }

  .Download-all-btn:hover{
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .all-btn-disabled {
  background-color: #c7c8cc;
  color: #666;
}

.all-d-btn-disabled{
  border: none;
    border-radius: 5px;
    padding: 7px;
    padding-inline: 12px;
    background-color: #c7c8cc;
    color: #666;
    transition: 0.3s ease;
}