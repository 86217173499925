// knowledgegraph.module.scss

.knowladgeGraphContainer {
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 99%;
  min-height: 60vh;
  padding: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  box-sizing: border-box;
}

.knowladgeGraphHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 10px;
  padding: 5px;
  align-items: flex-end;
  width: 99%;
  /* margin-left: 25px; */
}

.nodesTitle {
  display: flex;
  width: 100%;
  justify-content: space-around;
  position: sticky;
  top: 0%;
  background-color: #fff;
  /* margin-top: 10px; */
  padding: 10px;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.nodesData {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid grey;
  align-items: center;
}

.knowladgegraphDB {
  display: flex;
  align-items: center;
}

.knowladgeGraphbutton {
  margin-bottom: 15px;
}

.knowladgeGraphform {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 25px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 10px;
  padding: 25px;
  align-items: flex-end;
  width: 99%;
  /* margin-left: 25px; */
}

.knowladgeGraphformGroup {
  align-items: center;
  display: flex;
  gap: 10px;
}

.knowladgeGraphlabel {
  font-size: 14px;
}

.knowladgeGraphcheckbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.knowladgeGraphcheckboxInput {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .knowladgeGraphContainer {
    width: 95%;
    margin-left: 2.5%;
    padding: 15px;
  }

  .knowladgeGraphHeader,
  .knowladgeGraphform {
    flex-direction: column;
    align-items: stretch;
    width: 95%;
    margin-left: 2.5%;
    padding: 15px;
  }

  .knowladgegraphDB {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .knowladgeGraphformGroup {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .knowladgeGraphlabel {
    margin-bottom: 5px;
  }

  .knowladgeGraphbutton {
    width: 100%;
    margin-top: 15px;
  }
}

@media (max-width: 480px) {
  .knowladgeGraphContainer,
  .knowladgeGraphHeader,
  .knowladgeGraphform {
    width: 100%;
    margin-left: 0;
    padding: 10px;
  }
}

.knowladgegraphVisualContainer {
  display: flex;
  gap: 10px;
}

.knowladgegraphnode {
  width: 40%;
  height: 72vh;
  // overflow-y: scroll;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.knowladgegraphOutput {
  width: 60%;
  height: 72vh;
  overflow-y: scroll;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  // background-color: #c7c8cc;
}

.knowldgegraphTitle {
  font-size: 16px;
  font-weight: 600;
  // margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #acacac;
}

.knowladgeGraphRelationBox {
  display: flex;
  justify-content: space-around;
}

.knowladgeGraphVerticalLine {
  // border-left: 2px solid #808080;
  height: 55vh;
  margin: 4px 6px;
  position: absolute;
  // left: 48.5%;
}

.KnowladgeGraphNodes,
.knowladgeGraphRelationNode {
  width: 100%;
  height: 58vh;
  overflow-y: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  // padding: 10px;
  box-sizing: border-box;
  position: relative;
}

.NodescontentBox {
  display: flex;
  width: 100%;
  align-items: center;
}
.nodeSource {
  width: 45%;
  text-wrap: wrap;
  margin-right: 5%;
}
.nodeRelationship {
  width: 50%;
}
.NodeTitle {
  font-weight: 600px;
}
