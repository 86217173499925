.noFilesAvailableBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  .noFilesAvailableImage {
    height: 250px;
    width: 320px;
  }
  .title {
    font-weight: 600;
  }
}
