.data-source-table-container {
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.data-source-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px; /* Adds spacing between rows */
  border: none; /* Removes border from the table */
}

.data-source-table th, .data-source-table td {
  padding: 12px 15px;
  text-align: left;
  border: none; /* Removes borders from cells */
}

.data-source-table th {
  background-color: #d5d1d1;
  font-weight: bold;
  color: #333;
  border-bottom: 2px solid #e5e5e5;
}

.data-source-table td {
  background-color: #fff;
  border-radius: 10px; /* Adds rounded corners to cells */
}

.data-source {
  display: flex;
  align-items: center;
  padding-left: 15px;
}

.data-source-table tbody tr {
  transition: background-color 0.3s;
}

.data-source-table tbody tr:hover {
  background-color: #f1f1f1;
}
