.chatbotMainContainer {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 10px;
  width: 100%;
  height: 90vh;
  // display: flex;
  margin-inline: 15px;
  .ChatBotContainer {
    width: 65%;
    // box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    //   rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    background: #c8e9ff;
    background: radial-gradient(circle, #c8e9ff 0%, white 44%, #dcfcff 81%);
    background: #b5d5f9;
    background: radial-gradient(
      circle,
      #d4e8ff -37%,
      #f1faff 51%,
      #e0fcff 100%
    );
    height: 100%;
    .ChatBotHeader {
      box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
      background-color: #fff;
    }
    .ChatBotContent {
      height: 87%;
      overflow-y: scroll;
      margin-top: 10px;
      position: relative;
      .userQuestionBox {
        display: flex;
        gap: 5px;
        padding-bottom: 20px;
        margin-inline: 10px;
        .userQuestion {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          background-color: #bedbff;
          font-size: 14px;
          display: flex;
          flex-direction: column;
          align-items: end;
          padding: 5px;
          margin-top: 10px;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
          .chatTime {
            font-size: 11px;
          }
        }
      }
      .userAnswerBox {
        display: flex;
        gap: 5px;
        margin-inline: 10px;
        .chatNimbusnextLogo {
          height: 30px;
          width: 30px;
          padding: 3px;
          border-radius: 50%;
          box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
            rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        }
        .userAnswer {
          border-top-right-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: end;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          background-color: #ffffff;
          font-size: 14px;
          padding: 5px;
          margin-bottom: 20px;
          margin-top: 10px;
          box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
          .chatTime {
            font-size: 11px;
          }
        }
      }
      .ChatBotInputContainer {
        position: fixed;
        top: 92%;
        z-index: 10;
        width: 46%;
        .InputWrapper {
          position: relative;
          width: 97%;
          margin-inline: 10px;

          .ChatBotInput {
            width: 100%;
            // border: none;
            height: 46px;
            border-radius: 50px;
            padding-right: 50px;
            // background: #6b859e;
            background: #ffffff;
            border: 1px solid rgb(248, 248, 248);
            font-size: 16px;
            line-height: 38px;
            padding-left: 10px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
              rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            &:focus {
              border: none;
            }
          }

          .ChatBotButton {
            position: absolute;
            right: 9px;
            top: 50%;
            transform: translateY(-50%);
            height: 32px;
            width: 32px;
            border: none;
            background-color: #005fc6;
            color: white;
            border-radius: 15px;
            box-shadow: rgba(66, 66, 79, 0.25) 0px 2px 5px -1px,
              rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .cyntlerpdfContainer {
    width: 50%;
    // border: 2px solid grey;
    height: 100%;
    // margin: 10px;
  }
}
.iframeChatContainer {
  width: 100%;
  height: 88vh;
}
.chatbotHeader {
  background-color: #fff;
  height: 60x;
  align-items: center;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  margin-bottom: 3px;
  margin-left: 10px;
  .chatbotTitle {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin-left: 20px;
  }
  .chatbotHeaderDropdownsBox {
    display: flex;
  }
}
