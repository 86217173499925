.modal-header .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    color: #000; /* Adjust the color as needed */
    cursor: pointer;
  }

  .custom-dropdown {
    position: relative;
  }
  
  .custom-dropdown select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 100%;
    padding-right: 30px; /* Adjust the padding for the arrow */
    border: 1px solid #ced4da;
    border-radius: 4px;
    /* background-color: white; */
    cursor: pointer;
  }
  
  .dropdown-arrow {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the right position */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #495057; /* Arrow color */
  }
  