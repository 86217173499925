// $sliderColor: #2196F3;
$sliderColor: #4d53e0;
.configureContainer{
    margin-bottom: 20px;
    .textCleaningBox{
        display: flex;
        border-radius: 15px;
        padding-inline: 1rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
        align-items: center;
        // border: 1px solid #ccc; 
        .switch {
            // position: relative;
            // display: inline-block;
            width: 60px;
            height: 34px;
          }
          
          .switch input {
            opacity: 0;
            width: 0;
            height: 0;
          }
          
        .textCleaningCheck{
            width: 250px;
            margin-right: 50px;
            // border: 1px solid #ccc;
            // border-radius: 10px;
            padding: 10px;   
        }
    }
}
    