@import "../../styles/helpers";

.root {
  padding-top: 0;
  :global {
    .input-group {
      overflow: hidden;
    }

    .input-group-prepend {
      background-color: $white;
      transition: background-color ease-in-out 0.15s;
      padding-right: 1rem;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .form-control {
      background-color: $white;
      height: 45px;
      border: none;
      border-radius: 0.5rem;
      padding-left: 1rem;
      font-size: $font-size-base;
    }
    // .navbar {
    //   padding-top: 0 !important;
    // }

    .nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .title-container {
      position: absolute;
      left: 0;
      margin-left: 30px;
      margin-top: 8px;
      /* Add additional styling here */
    }

    .title-container h2 {
      font-size: 32px;
    }
  }
}
.project-title {
  margin-top: 10px;
}

.navbarBlock {
  display: flex;
  color: $icon-color;
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 44px;
  width: 44px;
  border-radius: 50%;

  img {
    height: 100%;
  }
}

.menuIcon {
  fill: $icon-color;
}

.notificationImage {
  margin: 8px auto 12px;
  width: 100%;
}

.mariaImage {
  margin-right: 12px;
  width: 40px;
  height: 40px;
}

.count {
  position: absolute;
  width: 6px;
  height: 6px;
  top: 10px;
  right: 3px;
  background: $pink;
  border-radius: 50%;
}

.form-control::placeholder {
  color: #4d53e0;
  opacity: 1;
}

.dropdownProfileItem {
  display: flex;
  &:hover {
    svg {
      path {
        fill: $default;
        stroke: $default;
      }
    }
  }
}
.leftSideTitle {
  margin-left: 30px;
}

.Backtoworkspace {
  margin-right: 80px;
  cursor: pointer;
  max-width: 250px;
  @media (max-width: 767px) {
    margin-right: 10px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    margin-right: 10px;
  }

  .backName {
    font-size: 18px;
    font-weight: 600;
    color: blue;
    margin-bottom: 10px;
    // text-decoration: underline;
  }

  // style={{ marginRight: "80px", maxWidth: "250px" }}
}
