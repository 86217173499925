.summaryTextArea {
  // width: 95%;
  // margin-top: 30px;
  border: none;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 0 3px #a2a3ac;
  background-color: #f9f9fe;
  padding-inline: 10px;
  &:focus {
    border-bottom: #f9f9fe;
  }
}
