.tabList{
  font: size 18px; 
  padding-inline: 25px;
  margin: 0;

}


/* tabs-styles.css */

.react-tabs {
  /* font-family: 'Arial', sans-serif; */
  width: 100%;
}

.react-tabs .react-tabs__tab-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  /* border-bottom: 2px solid #ddd; */
  border-radius: 10px;
}

.react-tabs__tab-list{
  border: none;
}

.react-tabs .react-tabs__tab {
  cursor: pointer;
  padding: 0.5rem 1rem;
  /* margin-right: 10px; */
  border: none;
  border: 2px solid #ddd ;
  border-top-right-radius: 8px ;
  border-top-left-radius: 8px ;

  /* border-radius: 8px ; */
  /* background-color: #f9f9fe; */
  background-color: #f7f7f7;
  transition: color .1s ease-in-out, background-color .1s ease-in-out, border-color .1s ease-in-out;
}

.react-tabs .react-tabs__tab.react-tabs__tab--selected {
  color: #000;
  background-color: #fff;
  border: 2px solid #ddd;
  border-bottom: none;
  padding-bottom: 12px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-right-radius: 10px;
}

/* .react-tabs .react-tabs__tab:hover {

  background-color: #e2e1e1;
  background-color: #fff;
} */

.react-tabs .react-tabs__tab-panel {
  padding: 2rem 1rem;
  border: 2px solid #ddd;
  border-radius: 8px ;
  background-color: #fff;
  margin-top: -2px;
  max-width: 98%;
  margin-left: 10px;
  min-height: 65vh;
  max-height: 100%;
}
