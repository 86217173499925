.textCleaningBox{
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.textQualityBox{
  display: inline-block;
  margin-top: 40px;
}
.additionaltextBox{
  display: flex;
  gap: 10px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .3s;
  transition: .3s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform:  translateX(26px);
  transform: translateX(26px);
}

.ButtonContainer{
  margin-top: 30px;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  
}

.ProfanityCheckBox{
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
  /* display: flex; */
  /* padding: 20px; */
  /* margin-bottom: 25px; */
  /* gap: 10px; */
  margin-top: 40px;
  /* width: 35%; */
  display: inline-block;
  /* border-radius: 10px; */
  /* align-items: center; */
  /* justify-content: space-between; */
}
.GenerateSummary{
  display: flex;
  gap: 10px;
}
.wordLimitInput{
  display: flex;
  gap: 10px;
}

.wordLimitInput{
  width: 60px;
  padding-left: 8px;
  height: 26px;
}