.leftSideTitle {
  margin-left: 30px;
}

.Backtoworkspace {
  margin-right: 80px;
  cursor: pointer;
  max-width: 250px;
  @media (max-width: 767px) {
    margin-right: 10px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    margin-right: 10px;
  }

  .backName {
    font-size: 18px;
    font-weight: 600;
    color: blue;
    margin-bottom: 10px;
    // text-decoration: underline;
  }

  // style={{ marginRight: "80px", maxWidth: "250px" }}
}
