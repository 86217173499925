.container {
  padding: 20px;
  margin-left: 20px;
  @media (max-width: 425px) {
    margin-left: 0;
  }
  .GenerateSummaryBox {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    display: flex;
    padding: 20px;
    margin-bottom: 25px;
    gap: 10px;
    width: 50%;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1190px) {
      width: 95%;
      flex-wrap: wrap;
      // justify-content: center;
      // gap: 20px;
    }
    @media (max-width: 970px) {
      width: 95%;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }
    @media (max-width: 425px) {
      width: 100%;
      margin-left: 0;
    }
    .GenerateSummary {
      display: flex;
      gap: 10px;
      .wordLimitInput {
        width: 60px;
        padding-left: 8px;
        height: 26px;
        border: 2px solid #ddd;
        border-radius: 5px;
      }
    }
  }

  .tableMain {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 10px 20px 30px 40px;
    .tableHead {
      border-radius: 5px;
      border: 1px solid #ddd;
      padding: 8px;
      width: 10px;
    }
    .heading {
      border-radius: 10px 20px 30px 40px;
      border: 1px solid #ddd;
      padding: 8px;
      font-weight: 600;
    }
    .tableData {
      border: 1px solid #ddd;
      padding: 8px;
    }
  }
}
.ButtonContainer {
  margin-top: 30px;
  display: flex;
  // padding: 20px;
  // margin-bottom: 25px;
  gap: 10px;
  width: 50%;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1190px) {
    width: 95%;
    flex-wrap: wrap;
    // justify-content: center;
    // gap: 20px;
  }
  @media (max-width: 970px) {
    width: 95%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  @media (max-width: 425px) {
    width: 100%;
    margin-left: 0;
  }
  .GenerateSummary {
    display: flex;
    gap: 10px;
    .wordLimitInput {
      width: 60px;
      padding-left: 8px;
      height: 26px;
      border: 2px solid #ddd;
      border-radius: 5px;
    }
  }
}

.tableMain {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 10px 20px 30px 40px;
  .tableHead {
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 8px;
    width: 10px;
  }
  .heading {
    border-radius: 10px 20px 30px 40px;
    border: 1px solid #ddd;
    padding: 8px;
    font-weight: 600;
  }
  .tableData {
    border: 1px solid #ddd;
    padding: 8px;
  }
}
.ButtonContainer {
  //   margin-top: 30px;
  display: flex;
  // padding: 20px;
  // margin-bottom: 25px;
  gap: 10px;
  width: 50%;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1190px) {
    width: 95%;
    flex-wrap: wrap;
    // justify-content: center;
    // gap: 20px;
  }
  @media (max-width: 970px) {
    width: 95%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  @media (max-width: 425px) {
    width: 100%;
    margin-left: 0;
  }
  .GenerateSummary {
    display: flex;
    gap: 10px;
    .wordLimitInput {
      width: 90px;
      padding-left: 8px;
      height: 26px;
      border: 2px solid #ddd;
      border-radius: 5px;
    }
  }
}
.summaryBox {
  width: 100%;
  .summaryBoxLabel {
    margin-bottom: 5px;
    //   font-size: 13px;
  }
  .summaryBoxhelperText {
    font-size: 13px;
  }
}

.tableMain {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 10px 20px 30px 40px;
  .tableHead {
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 8px;
    width: 10px;
  }
  .heading {
    border-radius: 10px 20px 30px 40px;
    border: 1px solid #ddd;
    padding: 8px;
    font-weight: 600;
  }
  .tableData {
    border: 1px solid #ddd;
    padding: 8px;
  }
}

.ButtonContainer {
  margin-top: 30px;
  display: flex;
  gap: 15px;
  justify-content: flex-start;
}

.nodatafoundbox {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  gap: 20px;
  align-items: center;
}

.DeleteBox {
  display: flex;
  flex-direction: column;
  gap: 50px;
  .btnBox {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
